import style from "./FAQs.module.css"
import Faq from "react-faq-component";
import { data } from "./data";

function FAQs() {

    const styles = {
        bgColor: 'transparant',
        titleTextColor: "#2992e7",
        rowTitleColor: "black",
        rowTitleTextSize:"1.2rem",
        rowContentTextSize: "1.2rem",
        rowContentColor: '#2992e7',
        rowContentPaddingRight:"3rem",
        arrowColor: "#2992e7",
        titleTextSizePaddingBottom:"3rem"
    };

    const config = {
        animate: true,
        tabFocus: true
    };

    return (
        <div className={style.mainContainer}>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    )
}

export default FAQs;