import Intro from './intro/Intro';
import Benefits from "./benefits/Benefits"
import FAQs from "./FAQs/FAQs"
import Banner from '../../../components/banner/Banner';

function ITHardware() {
  return (
    <div>
      <Banner name={"IT-Hardware"}/>
      <Intro />
      <Benefits />
      <FAQs />
    </div>
  )
}

export default ITHardware;
