import style from "./Innovation.module.css"
import Fade from 'react-reveal/Fade';
import { data } from "./data";
import Card from '@mui/material/Card';
import { Link } from "react-router-dom";

function Innovation() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
        <Fade bottom>
          <h2>Expediting Technological <span>Innovation Through Software & Development</span> Solutions</h2>
        </Fade>
      </div>
      <div className={style.section2}>
        <div className={style.cardWrapper}>
        {
          data.map((val, index) => {
            return <Fade bottom><Link to={val.link} className={style.link}><Card key={index} className={style.box}>
              <Fade left={index%2===0} right={!index%2===0}>
                <h3>{val.heading}</h3>
              </Fade>
              <Fade left={index%2===0} right={!index%2===0}>
                <span>{val.text}</span>
              </Fade>
            </Card></Link></Fade>
          })
        }
        </div>
      </div>
    </div>
  )
}

export default Innovation;








