export const ourServices = [
    {
        name: "Mobile Application Development",
        link: "/services/mobile-application-development"
    },
    {
        name: "Web Application Development",
        link: "/services/web-application-development"
    },
    {
        name: "Android App Development",
        link: "/services/android-app-development",
    },
    {
        name: "iOS App Development",
        link: "/services/iOS-app-development",
    },
    {
        name: "Blockchain Development",
        link: "/services/blockchain-development",
    },
    {
        name: "Digital Marketing Services",
        link: "/services/digital-marketing",
    },
]