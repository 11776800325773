import style from "./TechStackUse.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

function TechStackUse() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Cutting-Edge Blockchain Development Solutions <span>Tailored to Your Business Needs</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        In the ever-evolving blockchain landscape, our team of expert developers stays at the forefront of emerging technologies. We work with various blockchain platforms, including Ethereum, Hyperledger Fabric, Binance Smart Chain, and many more. Our technology stack empowers us to create custom solutions that fit your business needs, regardless of the project's complexity.
                    </p>
                </Fade>
            </div>
        </div>
    )
}

export default TechStackUse;
