export const data = {"title": "FAQs Based on iOS Development Services",
"rows": 
[
    {
        title: "What is the typical timeline for developing an iOS app?",
        content: "The timeline for iOS app development varies depending on the project's complexity. Generally, a simple app may take a few months, while more complex apps with advanced features may require six months or longer.",
    },
    {
        title: "What is the cost of developing an iOS app?",
        content: "The cost of iOS app development can vary significantly. It depends on the app's complexity, features, design, and more factors. We offer customized quotes after understanding your specific requirements.",
    },
    {
        title: "Do you provide support after the app is launched?",
        content: "Yes, we offer post-launch support, including app maintenance, updates, and troubleshooting to ensure your app continues to perform optimally.",
    },
    {
        title: "Will my iOS app be compatible with the latest iPhone and iPad models?",
        content: "Yes, we ensure that your iOS app is designed to be compatible with the latest iOS devices and operating system versions.",
    },
    {
        title: "Do you assist with App Store submission and optimization?",
        content: "Yes, we guide you through the process of submitting your app to the Apple App Store and offer strategies for optimizing its visibility and download rates.",
    },
    {
        title:"Can you integrate third-party services or APIs into my iOS app?",
        content:"We have experience integrating various third-party services and APIs to enhance your app's functionality, such as payment gateways, social media, and mapping services."
    },
]
}