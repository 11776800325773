import PayPerClickAdvertising from "./images/Pay-Per-ClickAdvertising.webp"
import SocialMediaMarketing from "./images/SocialMediaMarketing.webp"
import ContentMarketing from "./images/ContentMarketing.webp"
import EmailMarketing from "./images/EmailMarketing.webp"
import ConversionRateOptimization from "./images/ConversionRateOptimization.webp"
import AnalyticsandReporting from "./images/AnalyticsAndReporting.webp"


export const data = [
    {
        img: PayPerClickAdvertising,
        heading: "Pay-Per-Click Advertising (PPC)",
        text: "Our PPC experts can create and manage highly effective pay-per-click advertising campaigns, ensuring that your ads are seen by the right audience at the right time. We optimize ad spending to maximize ROI and deliver measurable results."
    },
    {
        img: SocialMediaMarketing,
        heading: "Social Media Marketing",
        text: "Engaging with your audience on social media platforms is a key component of a successful digital marketing strategy. We can help you develop and execute social media campaigns that build brand awareness, foster customer loyalty, and drive traffic to your website."
    },
    {
        img: ContentMarketing,
        heading: "Content Marketing",
        text: "Content is king on the internet, and we specialize in creating high-quality, relevant content that resonates with your target audience. Whether it's blog posts, infographics, videos, or more, we'll craft compelling content that establishes your authority and keeps your audience engaged."
    },
    {
        img: EmailMarketing,
        heading: "Email Marketing",
        text: "Stay connected with your customers through targeted email campaigns. Our email marketing services can help nurture leads, promote products or services, and keep your audience informed and engaged."
    },
    {
        img: ConversionRateOptimization,
        heading: "Conversion Rate Optimization (CRO)",
        text: "We analyze your website's performance and user behavior to identify areas where we can improve conversion rates. Our CRO strategies are designed to turn more visitors into loyal customers."
    },
    {
        img: AnalyticsandReporting,
        heading: "Analytics and Reporting",
        text: "We provide detailed insights into the performance of your digital marketing efforts. Our data-driven approach allows us to make informed decisions and continually refine your strategies for maximum results."
    }
]