import style from "./ContactInfo.module.css"
import Card from "@mui/material/Card"
import { data } from "./data"

function ContactInfo() {
    return (
        <div className={style.mainContainer}>
            <div className={style.container}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <span>{ele.img}</span>
                            <h3>{ele.heading}</h3>
                            <p>{Array.isArray(ele.details) ? (
                                ele.details.map((val, index) => (
                                    <p key={index}>{val}</p>
                                ))
                            ) : (
                                <p>{ele.details}</p>
                            )}</p>
                        </Card>
                    })
                }
            </div>
        </div>
    )
}

export default ContactInfo
