import FAQs from "./FAQs/FAQs";
import IndustriesServed from "./IndustriesServed/IndustriesServed";
import Benefits from "./benefits/Benefits";
import Intro from "./intro/Intro"
import OurTechStack from "./ourTechStack/OurTechStack";
import Process from "./process/Process";
import Banner from "../../../components/banner/Banner"

function WebApplicationDevelopment() {
  return (
    <div>
      <Banner name={"Web Application Development"} />
      <Intro />
      <OurTechStack />
      <Benefits />
      <Process />
      <IndustriesServed />
      <FAQs />
    </div>
  )
}

export default WebApplicationDevelopment;
