import Benefits from "./benefits/Benefits";
import IndustriesServed from "./industriesServed/IndustriesServed";
import Intro from "./intro/Intro";
import Process from "./process/Process";
import TechStackUse from "./techStackUse/TechStackUse";
import FAQs from "./FAQs/FAQs"
import Banner from "../../../components/banner/Banner"

function BlockchainDevelopment() {
  return (
    <div>
      <Banner name={"Blockchain Development"}/>
      <Intro />
      <TechStackUse />
      <Benefits />
      <Process />
      <IndustriesServed />
      <FAQs />
    </div>
  )
}

export default BlockchainDevelopment;
