import style from "./NewsLetter.module.css"
import Button from '@mui/material/Button';

function NewsLetter() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <span>Ready to bring your project to life?
                    Contact us today to discuss your ideas, challenges, and goals.
                    Let's collaborate and create something remarkable together.</span>
                <h2>Subscribe Our Newsletter</h2>
            </div>
            <div className={style.section2}>
                <div className={style.inputAndbutton}>
                    <input placeholder="Enter your email" type="email" required/>
                    <Button variant="contained">Subscribe</Button>
                </div>
                <span>At HHP Software Pvt Ltd, we are your partners in innovation, and we look forward to working with you.</span>
            </div>
        </div>
    )
}

export default NewsLetter;
