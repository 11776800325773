import ParkIcon from '@mui/icons-material/Park';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import MovieIcon from '@mui/icons-material/Movie';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import GroupIcon from '@mui/icons-material/Group';


export const data = [
    {
        logo: <ParkIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Environment",
    },
    {
        logo: <HomeIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Real Estate",
    },
    {
        logo: <SchoolIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Education",
    },
    {
        logo: <AcUnitIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Lifestyle",
    },
    {
        logo: <MovieIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Media & Entertainment",
    },
    {
        logo: <LocalAirportIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Travel",
    },
    {
        logo: <ShoppingCartIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Retail And Ecommerce",
    },
    {
        logo: <HealthAndSafetyIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Healthcare",
    },
    {
        logo: <GroupIcon sx={{fontSize:"2.5rem",color:"#2992e7"}}/>,
        name: "Public Sector",
    },
]