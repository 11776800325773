import htmlLogo from "./images/htmlLogo.png"
import cssLogo from "./images/cssLogo.png"
import jsLogo from "./images/JSlogo.png"
import reactLogo from "./images/reactLogo.png"
import angularLogo from "./images/angularLogo.png"
import vueLogo from "./images/vuelogo.png"
import nodeLogo from "./images/nodeLogo.png"
import pythonLogo from "./images/PythonLogo.png"
import rubyOnRailsLogo from "./images/RubyOnRailsLogo.png"
import javaLogo from "./images/javaLogo.png"
import laravelLogo from "./images/laravelLogo.png"
import phpLogo from "./images/phpLogo.png"
import expressLogo from "./images/expressLogo.png"
import mySQLLogo from "./images/MySQLLogo.png"
import postgreSQLLogo from "./images/PostgreSQLLogo.png"
import mongodbLogo from "./images/mongodbLogo.png"
import cassandraLogo from "./images/cassandraLogo.png"

export const frontendTechStack = [htmlLogo,cssLogo,jsLogo,reactLogo,angularLogo,vueLogo]

export const backendTechStack = [nodeLogo,expressLogo,pythonLogo,rubyOnRailsLogo,javaLogo,laravelLogo,phpLogo]

export const databases = [mySQLLogo,postgreSQLLogo,mongodbLogo,cassandraLogo]