export const data = [
    {
        heading:"Discovery and Ideation",
        text:"We dive deep into your vision and objectives. Our team collaborates with you to brainstorm ideas, refine concepts, and identify the key features and functionalities that will make your mobile app stand out."
    },
    {
        heading:"Design Thinking",
        text:"We apply design thinking principles to create intuitive and visually appealing user interfaces. Our designers work closely with you to understand your target audience and deliver an engaging user experience."
    },
    {
        heading:"Agile Development",
        text:"We embrace Agile methodologies to streamline development. It allows for flexibility, rapid iterations, and continuous feedback. It ensures that your app evolves in response to changing requirements and market dynamics."
    },
    {
        heading:"Cross-Functional Collaboration",
        text:"Our development teams work collaboratively across functions. Developers, designers, and QA engineers collaborate seamlessly, ensuring that every aspect of your app aligns with the project's goals & maintains a high-quality standard."
    },
    {
        heading:"Quality Assurance",
        text:"We rigorously test your app to identify and rectify issues, ensuring a bug-free and reliable final product. Our commitment to quality extends beyond launch day, with ongoing support and updates to keep your app performing optimally."
    },
    {
        heading:"User-Centric Approach",
        text:"We gather user feedback and analyze data throughout development to make informed decisions. This iterative approach ensures that your app meets and exceeds user expectations."
    },
    {
        heading:"Security and Compliance",
        text:"We prioritize the security of user data and ensure that your app complies with industry regulations. Robust encryption, authentication, and compliance measures are integral to our development process."
    },
    {
        heading:"Deployment and Launch",
        text:"We guide you through the deployment process, assisting with submissions to app stores and ensuring that your app is ready for a successful launch. Our goal is to maximize visibility and accessibility to your target audience."
    },
    {
        heading:"Post-Launch Support",
        text:"Your app's journey continues after launch day. We provide ongoing support, updates, and maintenance services to keep your app up-to-date, secure, and responsive to user needs."
    }
]