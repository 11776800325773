import StarIcon from '@mui/icons-material/Star';
let stars = [<StarIcon/>,<StarIcon/>,<StarIcon/>,<StarIcon/>,<StarIcon/>]


export const data = [
    {
        name: "Aryan Enterprises",
        stars: stars.map((ele) => {
            return ele;     
        }),
        reviews: "HHP Software is the go-to team for mobile application development. They delivered a feature-rich and intuitive app that has increased our customer base and streamlined our internal processes. Their dedication to quality and attention to detail are truly commendable. We are grateful for the fantastic app they've created for us."
    },
    {
        name: "Arthum",
        stars: stars.map((ele) => {
            return ele;     
        }),
        reviews: "HHP Software sets the standard for excellence in mobile app development services. Their team's unparalleled technical expertise, innovative thinking, and deadline commitment. Our app's success in the market is a testament to their exceptional work. We look forward to collaborating with them on future projects and highly recommend their services to others."
    },
    {
        name: "Driro Shop",
        stars: stars.map((ele) => {
            return ele;     
        }),
        reviews: "Working with HHP Software on our mobile app development project was a game-changer. They demonstrated a deep understanding of our industry and seamlessly integrated our vision into the app's design and functionality. The app's performance and user interface are exceptional, and our users love it. I highly recommend HHP Software to anyone seeking top-tier mobile app development services. "
    },
    {
        name: "IIT KANPUR",
        stars: stars.map((ele) => {
            return ele;     
        }),
        reviews: "HHP Software exceeded our expectations. From concept to execution, their team displayed unwavering professionalism and creativity. Our app is now the pride of our business, delivering exceptional user experiences and boosting customer engagement. Thank you for your outstanding work! Their attention to detail and commitment to delivering high-quality solutions sets HHP Software apart. We appreciate their dedication to ensuring our project not only met but surpassed our envisioned goals.  "
    }
]