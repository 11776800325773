import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom"


function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Empowering Innovation Through <span>Blockchain Development Services</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        As a leading Blockchain Development Company, we're dedicated to unleashing the true potential of blockchain technology for your organization. Our mission is to provide tailored blockchain solutions that revolutionize how you conduct business, securely exchange data, and interact with your customers.
                    </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;

