import serviceIcon1 from "./images/service-icon-01.webp"
import serviceIcon2 from "./images/service-icon-02.webp"
import serviceIcon3 from "./images/service-icon-03.webp"
import serviceIcon4 from "./images/service-icon-04.webp"

export const data = [
    {
        logo: serviceIcon1,
        heading: "Strategic Clarity and Direction",
        text: "At the heart of our services lies a clear and compelling vision tailored to your business. We deeply understand your goals and aspirations, mapping out a strategic roadmap that steers your enterprise toward success."
    },
    {
        logo: serviceIcon2,
        heading: "Unleashing Potential Through Technology",
        text: "With a shared vision as our guiding star, we harness the potential of cutting-edge technologies to propel your business to new heights. Our solutions bridge your current reality and your envisioned success, enabling ."
    },
    {
        logo: serviceIcon3,
        heading: "Innovative Solutions Aligned with Goals",
        text: "Our vision isn't just a concept; it's the driving force behind the innovative solutions we create. Every app, and every line of code is infused with our commitment to realizing your business's vision, ensuring alignment and impactful outcomes."
    },
    {
        logo: serviceIcon4,
        heading: "Empowering Growth and Sustainability",
        text: "Beyond the present, our vision a prosperous future for your business. We build solutions that address your current needs and lay the groundwork for sustained growth, ensuring your business's long-term success and relevance."
    }
]