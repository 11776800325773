import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom"

function Intro() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
        <Flip top>
          <h1>Your Partner for Cutting-Edge <span>Mobile Application Development Services</span></h1>
        </Flip>
        <Fade left>
          <p>
            Collaborating with renowned global brands, HHP Software specializes in the conception and creation of cutting-edge digital products. Our expertise lies in crafting seamless user experiences across the most advanced platforms and devices.
          </p>
        </Fade>
        <Bounce>
          <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
        </Bounce>
      </div>
    </div>
  )
}

export default Intro;
