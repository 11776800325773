import style from './TeamMember.module.css';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { data, socialMediaIcons } from './data';
import { Card } from '@mui/material';
import { Link } from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TeamMember() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let slidesToShow = 4;
    let buttonName = "Join Our Team";

    if (windowWidth <= 1065) {
        slidesToShow = 3;
    }
    if (windowWidth <= 750) {
        slidesToShow = 2;
    }
    if (windowWidth <= 550) {
        slidesToShow = 1;
    }
    if (windowWidth <= 250) {
        buttonName = "Join";
    }


    let settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 3000,
        autoplay: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        variableWidth: false,
        pauseOnHover: true
    };

    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <div className={style.heading}>
                    <h5>TEAM MEMBER</h5>
                    <span>Our Innovative & Visionary Team</span>
                </div>
                <Link to="/contactUs"><Button variant="outlined" sx={{ height: "3rem", fontSize: "1.1rem" }} className={style.btn}>{buttonName}</Button></Link>
            </div>
            <Slider {...settings} className={style.section2}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <div className={style.imgContainer}>
                                <img src={ele.img} alt='error' className={(ele.name === "Rahul Rajpoot") ? style.imgScale : style.img} />
                            </div>
                            <div className={style.nameAndDesignation}>
                                <h3>{ele.name}</h3>
                                <span>{ele.designation}</span>
                                <div className={style.socialMediaIcons}>
                                    {
                                        socialMediaIcons.map((ele, index) => {
                                            return <Link to={ele.link} target="_blank" rel="noopener noreferrer" className={style.link}><span key={index}>{ele.icon}</span></Link>
                                        })
                                    }
                                </div>
                            </div>
                        </Card>
                    })
                }
            </Slider>
        </div>
    )
}

export default TeamMember;
