import Intro from "./intro/Intro"
import Benefits from "./benefits/Benefits"
import TermsAndConditions from "./termsAndConditions/TermsAndConditions"
import FAQs from "./FAQs/FAQs"
import Banner from "../../../components/banner/Banner"

function ITManpower() {
  return (
    <div>
      <Banner name={"IT-Manpower"}/>
      <Intro />
      <Benefits />
      <TermsAndConditions />
      <FAQs />
    </div>
  )
}

export default ITManpower;
