export const data = [
    {
        heading:"Web Application Development",
        text:"Our web developers improve your website's frontend and backend by using the latest web development technology stack. Shape your brand personality with responsive web designs and libraries.",
        link:"/services/web-application-development"
    },
    {
        heading:"Blockchain Development",
        text:"Our web developers improve your website's frontend and backend by using the latest web development technology stack. Shape your brand personality with responsive web designs and libraries.",
        link:"/services/blockchain-development"
    },
    {
        heading:"Android App Development",
        text:"Our Android app development services are tailored to unlock the full potential of the world's most popular mobile operating system. We specialize in crafting user-friendly, scalable, high-performance Android applications.",
        link:"/services/android-app-development"
    },
    {
        heading:"IOS App Development",
        text:"Our team of iOS developers is well-versed in Swift and Objective-C, ensuring your iOS app is visually stunning, highly functional, & user-friendly. We work closely with you to understand your business objectives and design custom iOS solutions.",
        link:"/services/iOS-app-development"
    },
    {
        heading:"Digital Marketing Services",
        text:"We offer a wide range of services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, and more, all tailored to your unique goals and budget.",
        link:"/digitalMarketing"
    },
    {
        heading:"Mobile App Development",
        text:"As a leading mobile app development company, we build a robust mobile app that makes sense of your user's needs, budget, & time. We customize and develop custom Android, iOS, & desktop apps with our app development services.",
        link:"/services/mobile-application-development"
    }
]