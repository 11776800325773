import FactoryIcon from '@mui/icons-material/Factory';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import PublicIcon from '@mui/icons-material/Public';


export const data = [
    {
        icon: <FactoryIcon />,
        heading: "Expertise",
        text: "Our team comprises seasoned professionals who are masters of their craft. We stay ahead of industry trends and use the latest technologies to provide top-notch solutions."
    },
    {
        icon: <TaskAltIcon />,
        heading: "Quality Assurance",
        text: "Our rigorous quality assurance process ensures that every project is delivered bug-free and in its best possible form."
    },
    {
        icon: <TouchAppIcon />,
        heading: "Innovation",
        text: "Your goals are at the heart of our strategy. We collaborate with you, keeping your objectives at the forefront of our digital transformation initiatives."
    },
    {
        icon: <PublicIcon />,
        heading: "Global Reach",
        text: "While we are headquartered in [Location], our reach is global. We have successfully delivered projects for clients around the world."
    },
]