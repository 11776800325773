import style from "./Intro.module.css"
import Card from '@mui/material/Card';
import { data } from "./data";
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';

function Intro() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
          <Flip top>
            <h1>Unlocking the Pinnacle of <span>Exceptional Services</span></h1>
          </Flip>
        <Fade left>
          <p>At HHP Software Pvt Ltd, we transcend the role of a mere technology company – we are your steadfast ally in transforming your digital aspirations into tangible reality. Our unwavering commitment to innovation, a pursuit of excellence, and an unyielding dedication to our clients have positioned us as a formidable leader in the realms of mobile and web development.</p>
        </Fade>
      </div>
      <div className={style.section2}>
        {
          data.map((val, index) => {
            return <Fade left={!index} right={index}><Card key={index} className={style.box}>
              <div>
                <Fade buttom>
                  <h2>{val.heading}</h2>
                </Fade>
              </div>
              <Fade buttom>
                <span>{val.text}</span>
              </Fade>
            </Card></Fade>
          })
        }
      </div>
    </div>
  )
}

export default Intro;
