import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom";

function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>IT Manpower <span>Services</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        An IT manpower services provider is a company or agency that specializes in supplying skilled personnel to meet the staffing needs of organizations in the information technology (IT) sector. These providers play a crucial role in helping businesses find and hire qualified professionals for various IT roles. Here's an overview of the key aspects related to IT manpower services:
                    </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;
