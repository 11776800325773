export const data = [
    {
        heading:"Idea Conceptualization",
        text:"We begin by understanding your app idea and its purpose, helping you refine it for maximum impact."
    },
    {
        heading:"Design and Prototyping",
        text:"Our design team creates wireframes and prototypes to visualize the app's interface and user flow, ensuring it aligns with your vision."
    },
    {
        heading:"Development",
        text:"Our developers get to work, bringing the design to life, coding, and testing at every step."
    },
    {
        heading:"Testing and Quality Assurance",
        text:"We rigorously test your app for functionality, performance, and security, fixing any issues."
    },
    {
        heading:"Deployment",
        text:"Your iOS app is prepared for submission to the App Store, following all guidelines and requirements."
    },
    {
        heading:"Maintenance and Updates",
        text:"We provide ongoing support, including app updates and enhancements, to keep your app relevant and competitive."
    }
]