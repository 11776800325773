import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./LogoSlider.module.css"
import { useState, useEffect } from "react";
import { logoArr } from "./data";

function LogoSlider() {


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let slidesToShow = 7;

  if (windowWidth <= 750) {
    slidesToShow = 6;
  }
  if (windowWidth <= 750) {
    slidesToShow = 5;
  }
  if (windowWidth <= 400) {
    slidesToShow = 4;
  }
  if (windowWidth <= 300) {
    slidesToShow = 3;
  }
  if (windowWidth <= 250) {
    slidesToShow = 2;
  }

  let settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: false,
    pauseOnHover: true
  };

  return (
    <Slider {...settings} className={style.mainContainer}>
      {
        logoArr.map((data, index) => {
          return <div className={style.box}>
            <img src={data} alt="error" key={index} />
          </div>
        })
      }
    </Slider>
  )
}

export default LogoSlider;
