import React from 'react'
import style from "./Header.module.css"
import { Link } from "react-router-dom";
import { pages } from './data';
import hhpLogo from "./HHPlogo.webp"

function Header() {

  return (
    <div className={style.mainSection}>
      <div className={style.wrapper}>
        <div>
          <Link to="/"><img src={hhpLogo} alt='error' className={style.webLogo} /></Link>
        </div>
        <ul className={style.nav} >
          {
            pages.map((data, index) => {
              return <Link to={data.link} className={style.link}><li key={index}>{data.name}</li></Link>
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default Header;
