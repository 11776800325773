import style from "./IndustriesServed.module.css"
import blockChain from "./blockChain.png"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { data } from "./data";

function IndustriesServed() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <div className={style.heading}>
                    <Fade left>
                        <h3>Industries We Have Served As A Blockchain Development Company</h3>
                        <p>
                            At HHP Software, we believe in the transformative power of blockchain technology. Join us on this journey to redefine the way you do business. Contact us today to discuss how blockchain can empower your organization and enhance your competitiveness in the digital age.
                        </p>
                    </Fade>
                </div>
                <div className={style.imageContainer}>
                    <Fade right>
                        <img src={blockChain} alt="error" />
                    </Fade>
                </div>
            </div>
            <div className={style.section2}>
                {
                    data.map((ele, index) => {
                        return <div key={index} className={style.box}>
                            <Flip top>
                                <span>{ele.logo}</span>
                                <p>{ele.name}</p>
                            </Flip>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default IndustriesServed;
