export const data = [
    {
        link:"/services/mobile-application-development",
        pageName:"Mobile Application Development"
    },
    {
        link:"/services/web-application-development",
        pageName:"Web Application Development"
    },
    {
        link:"/services/android-app-development",
        pageName:"Android App Development"
    },
    {
        link:"/services/iOS-app-development",
        pageName:"iOS App Development"
    },
    {
        link:"/services/blockchain-development",
        pageName:"Blockchain Development"
    }
]