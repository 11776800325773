import { useState,useEffect } from "react";
import style from "./WhatWeDo.module.css";
import { Cursor, useTypewriter } from 'react-simple-typewriter'
import image1 from "./img/digital-transformation.png"
import image2 from "./img/technology-consulting.png"
import image3 from "./img/intelligence-automation.png"
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

function Home() {
  
  let textArray = ["Digital Transformation", "Technology Consulting", "Intelligence Automation"];  
  let imgArray = [image1,image2,image3]

  let [val,setVal] = useState(0);
  let [typeEffect, { isDeleting }] = useTypewriter({
    words:textArray,
    loop:{},
    typeSpeed:100, 
    deleteSpeed:40,
    cursor:true,
  })  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVal(prevVal => (prevVal + 1) % imgArray.length);
    }, 5200);
    return () => clearInterval(intervalId);
  }, [imgArray.length]);


  return (
    <div className={style.mainSection}>
    <div className={style.section1}>
    <div className={style.heading}> 
    <p>Revolutionizing Global Businesses Through</p>
    <p>
    {typeEffect}
    {isDeleting ? "" : <Cursor cursorStyle="|" className={style.cursor}/>}
    </p>   
    <p>HHP Software builds inspirational designs and robust solutions for web and mobile apps.
       We blend our expertise in tech & design to develop innovative digital solutions that bring
       changes to life.
    </p>  
    <Link to="/contactUs"><Button variant="outlined">Get A Quote</Button></Link>
    </div>
    <div className={style.image}>
    <img src={imgArray[val]} alt="error"/>
    </div>
    </div>
    </div>
  )
}

export default Home;
