import React from 'react'
import Intro from './intro/Intro'
import ServicesWeProvide from './servicesWeProvide/ServicesWeProvide'
import DigitalNeeds from './digitalNeeds/DigitalNeeds'
import TeamMember from './teamMember/TeamMember'
import NewsLetter from './newsletter/NewsLetter'
import Banner from '../../components/banner/Banner'

function About() {
  return (
    <div>
      <Banner name={"About Us"} />
      <Intro />
      <ServicesWeProvide />
      <DigitalNeeds />
      <TeamMember />
      <NewsLetter />
    </div>
  )
}

export default About;
