import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom"


function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                <h1>Web App <span>Development Company</span></h1>
                </Flip>
                <Fade left>
                <p>
                    At HHP Software, we are passionate about turning your innovative ideas into dynamic and user-friendly web applications. Whether you need a simple web app or a complex enterprise-level solution, our expert team of developers is here to bring your vision to life.
                </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;
