import FAQs from "./FAQs/FAQs";
import BusinessThrive from "./businessThrive/BusinessThrive";
import Intro from "./intro/Intro"
import OurApproach from "./ourApproach/OurApproach";
import Testimonials from "./testimonials/Testimonials";
import Banner from "../../../components/banner/Banner"

function MobileApplicationDevelopment() {
  return (
    <div>
      <Banner name={"Mobile Application Development"} />
      <Intro />
      <BusinessThrive />
      <OurApproach />
      <Testimonials />
      <FAQs />
    </div>
  )
}

export default MobileApplicationDevelopment;
