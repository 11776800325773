import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import style from "./ChooseUs.module.css"
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { data } from './data';
import Card from '@mui/material/Card';

function ChooseUs() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
       <Flip top>
       <h2>Why Choose HHP Software For Digital Transformation?</h2>
       </Flip>
      <span className={style.horizontalRuleIcon}>
      <HorizontalRuleIcon sx={{fontSize:"2rem"}}/>
      <HorizontalRuleIcon sx={{fontSize:"2rem"}}/>
      </span> 
      <Fade left>  
      <p>
      Embracing digital transformation is not just a business strategy; it's necessary in today's rapidly evolving landscape. At HHP Software, we understand the critical role technology plays in shaping the future of businesses. 
      </p> 
      </Fade>
      </div>
      <div className={style.section2}>
        {
          data.map((val,index) => {
             return <Fade bottom><Card key={index} className={style.box}>
              <Zoom>
              <img src={val.logo} alt='error'/>
              </Zoom>
              <Fade right>
              <h3>{val.heading}</h3>
              </Fade>
              <Fade left>
              <span>{val.text}</span>
              </Fade>
             </Card></Fade>
          })  
        }
      </div>
    </div>
  )
}

export default ChooseUs;
