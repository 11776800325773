import style from "./Testimonials.module.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "@mui/material";
import { data } from "./data";
import { useState,useEffect } from "react";

function Testimonials() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    let slidesToShow = 2;
  
    if (windowWidth <= 800) {
      slidesToShow = 1;
    }

    let settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 6000,
        autoplay: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        variableWidth: false,
        pauseOnHover:true
    };

    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <h3>Testimonials</h3>
                <h2>What our clients say</h2>
            </div>
            <Slider {...settings} className={style.section2}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <h3>{ele.name}</h3>
                            <span>{ele.stars}</span>
                            <p>{ele.reviews}</p>
                        </Card>
                    })
                }
            </Slider>
        </div>
    )
}

export default Testimonials;
