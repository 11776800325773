import style from "./Benefits.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { data } from "./data";
import { Link } from "react-router-dom";


function Benefits() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Unleash Your App's Full Potential with Our Expert <span>Android App Development Services</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        Choose HHP Software for your Android application development needs and gain access to a team of seasoned professionals, cutting-edge technology, user-centered design, and agile development. Let us transform your app concept into a powerful digital solution for your business.
                    </p>
                </Fade>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                    {
                        data.map((ele, index) => {
                            return <Card key={index} className={style.box}>
                                <Zoom><img src={ele.img} alt="error" /></Zoom>
                                <Fade left={index % 2 === 0} right={!index % 2 === 0}><h3>{ele.heading}</h3></Fade>
                                <Fade left={index % 2 === 0} right={!index % 2 === 0}><p>{ele.text}</p></Fade>
                            </Card>
                        })
                    }
                </div>
            </div>
            <div className={style.section3}>
                <h2>READT TO MAKE YOUR MOBILE APP EFFECTIVE ? <span>Get A Quote</span></h2>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Get A Quote</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Benefits;
