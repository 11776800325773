import StaffingSolutions from "../../../digitalMarketing/benefits/images/Pay-Per-ClickAdvertising.webp"
import RecruitmentAndPlacement from "../../../digitalMarketing/benefits/images/SocialMediaMarketing.webp"
import SpecializedITRoles from "../../../digitalMarketing/benefits/images/ContentMarketing.webp"
import IndustryExpertise from "../../../digitalMarketing/benefits/images/EmailMarketing.webp"
import ContractManagement from "../../../digitalMarketing/benefits/images/ConversionRateOptimization.webp"
import FlexibilityAndScalability from "../../../digitalMarketing/benefits/images/AnalyticsAndReporting.webp"

export const data = [
    {
        img: StaffingSolutions,
        heading: "Staffing Solutions",
        Objective: "To match organizations with qualified IT professionals based on their specific requirements",
        Services: ["Temporary Staffing", "Permanent Placement", "Contract-to-Hire"]
    },
    {
        img: RecruitmentAndPlacement,
        heading: "Recruitment and Placement",
        Objective: "Identifying, vetting, and placing suitable candidates in IT roles.",
        Services: ["Job Postings", "Candidate Screening", "Interview Coordination"]
    },
    {
        img: SpecializedITRoles,
        heading: "Specialized IT Roles",
        Objective: "Providing personnel for a wide range of IT positions.",
        Services: ["Software Developers", "System Administrators", "Network Engineers"]
    },
    {
        img: IndustryExpertise,
        heading: "Industry Expertise",
        Objective: " Understanding the unique needs of different industries to provide tailored staffing solutions.",
        Services: ["Healthcare", "Finance", "Technology"]
    },
    {
        img: ContractManagement,
        heading: "Contract Management",
        Objective: "Facilitating contract agreements between the client and the IT professional.",
        Services: ["Contract Negotiation", "Compliance Management", "Periodic Reviews"]
    },
    {
        img: FlexibilityAndScalability,
        heading: "Flexibility and Scalability",
        Objective: "Offering flexible staffing solutions that can scale with the organization's needs.",
        Services: ["Adjusting Team Size", "Accommodating Project-based Staffing", "Rapid Scaling Up or Down"]
    }
]