import style from "./TermsAndConditions.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { data } from "./data";

function TermsAndConditions() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h2>IT Manpower Team Terms and Conditions:</h2>
                    <p>It seems like there might be a slight typo in your question. If you're referring to the terms and conditions (T&C) related to an IT manpower team, I can provide you with a general overview of what these terms and conditions might include. However, for more specific and accurate information, it's advisable to refer to the actual terms and conditions provided by the relevant service provider or employer. Here's a general outline:</p>
                </Flip>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><h3>{ele.heading}</h3></Fade>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><p>{ele.text}</p></Fade>
                        </Card>
                    })
                }
                </div>
            </div>
            <div className={style.section3}>
                <h2>Ready to Transform Your Ideas into a IT Manpower Services? <span>Let's Get Started Today!</span></h2>
                <Bounce>
                    <Button variant="outlined">Contact Us for a Free Consultation</Button>
                </Bounce>
            </div>
        </div>
    )
}

export default TermsAndConditions;
