import starke_Logo from "./images/starke_logo.png"
import dreamer_Logo from "./images/dreamer_logo.png"
import IIT_kanpur_logo from "./images/IIT_Kanpur_Logo.svg.png"
import driro_Logo from "./images/driro.webp"
import anshCreation_Logo from "./images/ansh-creation-logo.png"
import avyatech_Logo from "./images/avyatech.png"
import aryan_Logo from "./images/aryan.webp"
import arthum_Logo from "./images/arthum.png"
import babyAmbulance_Logo from "./images/babyAmbulance_logo.png"
import faceglam_Logo from "./images/faceglamm.png"
import ashwanhealthcare_Logo from "./images/ashwanhealthcare.webp"
import ysisscreens_Logo from "./images/ysisscreens.webp"
import miaura_Logo from "./images/miaura_Logo.jpeg"
import rashitaaHostel_Logo from "./images/rashitaaHostel_logo.png"
import rayyanExport_Logo from "./images/rayyanExports_logo.png"
import basebiz_Logo from "./images/baseBiz_logo.png"
import dido_Logo from "./images/Dido_Logo.png"

export const logoArr = [
    IIT_kanpur_logo,
    driro_Logo,
    anshCreation_Logo,
    avyatech_Logo,
    aryan_Logo,
    arthum_Logo,
    babyAmbulance_Logo,
    faceglam_Logo,
    ashwanhealthcare_Logo,
    ysisscreens_Logo,
    starke_Logo,
    dreamer_Logo,
    miaura_Logo,
    rashitaaHostel_Logo,
    basebiz_Logo,
    rayyanExport_Logo,
    dido_Logo
]