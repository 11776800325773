export const data = [
    {
        heading: "Scope of Services",
        text:"Clearly define the scope of services that the IT manpower team is expected to provide. This could include roles to be filled, specific skills required, and the duration of the engagement."
    },
    {
        heading: "Contract Duration",
        text:"Specify the duration of the contract or engagement. This could range from short-term projects to long-term partnerships."
    },
    {
        heading: "Staffing Models",
        text:"Outline the staffing models available, such as temporary staffing, permanent placement, or contract-to-hire. Define the terms for each model."
    },
    {
        heading: "Recruitment Process:",
        text:"Describe the recruitment and placement process, including how candidates are sourced, screened, and presented to the client for consideration."
    },
    {
        heading: "Fees and Payment Terms:",
        text:"Clearly state the fees associated with the services provided. This may include placement fees, hourly rates, or other payment structures. Define the payment terms and schedule."
    },
    {
        heading: "Service Level Agreements (SLAs)",
        text:"Establish SLAs that outline the expected level of service, response times, and other key performance indicators. This helps set expectations for both the client and the IT manpower team."
    }
]