export const data = [
    {
        heading:"Discovery & Planning",
        text:"We start by getting to know your business, goals, and target audience. Our team collaborates with you to define the project scope, set objectives, and create a detailed roadmap for development."
    },
    {
        heading:"Design & User Experience",
        text:"Our talented designers create intuitive, visually appealing user interfaces that ensure an exceptional user experience. We pay attention to every detail, from layout and navigation to color schemes and branding."
    },
    {
        heading:"Quality Assurance & Testing",
        text:"Before your web application goes live, it undergoes rigorous testing to ensure functionality, security, and performance. We fix any issues and ensure your app meets the highest quality standards."
    },
    {
        heading:"Development & Coding",
        text:"Our experienced developers use the latest technologies and best practices to build your web application. We follow a transparent and agile development process, keeping you in the loop at every step."
    },
    {
        heading:"Deployment & Launch",
        text:"Once everything is thoroughly tested and refined, we deploy your web application to a production environment. We handle the technical aspects so you can focus on what matters most to your business."
    },
    {
        heading:"Maintenance & Support",
        text:"Our commitment doesn't end at launch. We provide ongoing maintenance and support services to keep your web application running smoothly, and we're ready to help with updates and improvements as your business evolves."
    }
]