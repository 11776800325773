import style from "./DigitalMarketingServices.module.css"
import DigitalMarketingImage from "./digitalMarketingServices.png";
import Fade from 'react-reveal/Fade';

function DigitalMarketingServices() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <h1>Our Digital <span>Marketing Services</span></h1>
            </div>
            <div className={style.section2}>
                <div className={style.image}>
                    <Fade left><img src={DigitalMarketingImage} alt="error" /></Fade>
                </div>
                <Fade right>
                <div className={style.description}>
                    <h2>Our Digital Marketing Expertise</h2>
                    <p>Our team of digital marketing professionals is dedicated to helping your business succeed in the online world. We offer a comprehensive range of digital marketing services tailored to your needs.</p>
                    <h2>Search Engine Optimization (SEO)</h2>
                    <p>Optimizing your website for search engines is crucial for increasing your visibility on the web. We employ the latest SEO techniques to ensure your website ranks higher in search engine results, driving more organic traffic and potential customers to your site.</p>
                </div>
                </Fade>
            </div>
        </div>
    )
}

export default DigitalMarketingServices;
