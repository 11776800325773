import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom";

function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>IT Hardware <span>Services</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        IT hardware services encompass a range of offerings that support the installation, maintenance, troubleshooting, and optimization of hardware components within an information technology environment. These services are essential for businesses to ensure the reliability, performance, and security of their IT infrastructure. Here's an overview of key aspects related to IT hardware services:
                    </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;
