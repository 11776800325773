import InstallationAndSetup from "../../../digitalMarketing/benefits/images/Pay-Per-ClickAdvertising.webp"
import MaintenanceAndMonitoring from "../../../digitalMarketing/benefits/images/SocialMediaMarketing.webp"
import TroubleshootingAndRepairs from "../../../digitalMarketing/benefits/images/ContentMarketing.webp"
import HardwareUpgrades from "../../../digitalMarketing/benefits/images/EmailMarketing.webp"
import SecurityServices from "../../../digitalMarketing/benefits/images/ConversionRateOptimization.webp"
import DataBackupAndRecovery from "../../../digitalMarketing/benefits/images/AnalyticsAndReporting.webp"


export const data = [
    {
        img: InstallationAndSetup,
        heading: "Installation and Setup",
        text: "Physical installation of servers, computers, networking devices, etc. Configuration of hardware settings. Verification of connections and compatibility."
    },
    {
        img: MaintenanceAndMonitoring,
        heading: "Maintenance and Monitoring",
        text: "Regular hardware inspections and cleaning. Monitoring hardware health and performance. Applying firmware and software updates."
    },
    {
        img: TroubleshootingAndRepairs,
        heading: "Troubleshooting and Repairs",
        text: "Diagnosing hardware problems. Replacing or repairing faulty components. Conducting system tests to verify fixes."
    },
    {
        img: HardwareUpgrades,
        heading: "Hardware Upgrades",
        text: "Assessing current hardware capabilities. Planning and executing upgrades (e.g., RAM, storage, processors). Ensuring compatibility with existing systems."
    },
    {
        img: SecurityServices,
        heading: "Security Services",
        text: "Implementing firewalls and other security measures. Regularly updating security software. Conducting security audits and risk assessments."
    },
    {
        img: DataBackupAndRecovery,
        heading: "Data Backup and Recovery",
        text: "Setting up backup systems and schedules. Testing data recovery processes. Implementing disaster recovery plans."
    }
]