import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import PrabhatKumar from "./images/PrabhatKumar.webp";
import ShashankOjha from "./images/shashankOjha.webp";
import DeepakKumar from "./images/deepakKumar.webp";
import SonaliSingh from "./images/sonaliSingh.webp";
import AjayJha from "./images/AjayJha.webp";
import adityaImage from "./images/Aditya_Shaw.png";
import rahulImage from "./images/Rahul.jpeg";
import PrinceKumar from "./images/princeKumar.webp"
import DheerajYadav from "./images/dhirajYadav.webp"
import AnujImg from "./images/anujImg1.png"


export const data = [
    {
        img: PrabhatKumar,
        name: "Prabhat Kumar",
        designation: "Founder and Director"
    },
    {
        img: ShashankOjha,
        name: "Shashank Ojha",
        designation: "CEO"
    },
    {
        img: DeepakKumar,
        name: "Deepak Kumar",
        designation: "PHP Developer"
    },
    {
        img: SonaliSingh,
        name: "Sonali Singh",
        designation: "Android Developer"
    },
    {
        img: PrinceKumar,
        name: "Prince Kumar",
        designation: "Flutter Developer"
    },
    {
        img: rahulImage,
        name: "Rahul Rajpoot",
        designation: "Backend Developer"
    },
    {
        img: AnujImg,
        name: "Anuj Srivastava",
        designation: "Full Stack Developer"
    },
    {
        img: AjayJha,
        name: "Ajay Jha",
        designation: "PHP Developer"
    },
    {
        img: adityaImage,
        name: "Aditya Shaw",
        designation: "ReactJs Developer"
    },
    {
        img: DheerajYadav,
        name: "Dheeraj Yadav",
        designation: "Digital Marketing"
    },
]


export const socialMediaIcons = [
    {
        icon: <FacebookIcon />,
        link: "https://www.facebook.com/hhpsoftware",
    },
    {
        icon: <InstagramIcon />,
        link: "https://www.instagram.com/hhpsoftwarepvtltd/",
    },
    {
        icon: <LinkedInIcon />,
        link: "https://www.linkedin.com/company/hhp-software-pvt-ltd/about/",
    },
    {
        icon: <TwitterIcon />,
        link: "https://twitter.com/SoftwareHhp",
    }
]