import img1 from "./images/InnovationAndDifferentiation.png"
import img2 from "./images/User-CentricDesign.png"
import img3 from "./images/ScalabilityAndAdaptability.png"
import img4 from "./images/Cross-PlatformCompatibility.png"
import img5 from "./images/SecurityAndDataProtection.png"
import img6 from "./images/EfficientProjectManagementAndTimelyDelivery.png"


export const data = [
    {   
        image:img1,
        heading:"Innovation and Differentiation",
        explanation:"Our expertise drives business innovation, creating unique solutions that set clients apart. Staying current with tech trends ensures our apps attract users and establish a distinctive market presence."
    },
    {
        image:img2,
        heading:"User-Centric Design",
        explanation:"Prioritizing UX/UI design, our apps are intuitive and visually appealing. Meeting user needs fosters a larger user base, increasing engagement and garnering positive reviews."
    },
    {
        image:img3,
        heading:"Scalability and Adaptability",
        explanation:"Our strategies prioritize scalability, allowing apps to grow seamlessly with the business. This adaptability ensures efficient performance amid increasing user loads, supporting business expansion."
    },
    {
        image:img4,
        heading:"Cross-Platform Compatibility",
        explanation:"Specializing in multi-platform development maximizes market reach. Cross-platform compatibility broadens the audience, expanding user base and revenue potential."
    },
    {
        image:img5,
        heading:"Security and Data Protection",
        explanation:"Security is paramount; robust measures safeguard user data and ensure compliance. Building trust among users and mitigating legal risks are integral to our development approach."
    },
    {
        image:img6,
        heading:"Efficient Project Management and Timely Delivery",
        explanation:"Streamlined project management ensures timely app delivery. Adhering to deadlines and clear communication allows businesses to launch promptly, gaining a competitive edge and adapting to market changes swiftly."
    },
]