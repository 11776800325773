import style from "./BannerImage.module.css"
import bannerImg from "./bannerImg.jpg"

function Banner({ name }) {
    return (
        <div className={style.mainContainer}>
            <img src={bannerImg} alt="error" className={style.img} />
            <div className={style.text}>
                <p>{name}</p>
            </div>
        </div>
    )
}

export default Banner;