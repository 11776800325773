import style from "./ContactForm.module.css"
import Button from "@mui/material/Button"
import Flip from 'react-reveal/Flip';
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {

    const [val, setVal] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    })

    const toastOfItemAdded = () => (toast.success('Sent successfully!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    }))

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_1mjlhn3', 'template_vtytkdn', form.current, 'Wx0pQxkyKTxzdbD5U')
            .then((result) => {
                setVal({
                    name: "",
                    email: "",
                    subject: "",
                    message: ""
                })
                toastOfItemAdded();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className={style.mainContainer}>
            <ToastContainer />
            <div className={style.section1}>
                <Flip top>
                    <h2>Get In Touch</h2>
                    <p>Feel free to reach out to us. We aim to respond to all inquiries promptly, typically within one business day.</p>
                </Flip>
            </div>
            <form className={style.section2} ref={form} onSubmit={sendEmail}>
                <input placeholder={"Enter your name"} value={val.name} onChange={(e) => { setVal(e.target.value) }} name="name" required />
                <input placeholder={"Enter your email"} value={val.email} onChange={(e) => { setVal(e.target.value) }} type="email" name="email" required />
                <input placeholder={"Enter Subject"} value={val.subject} onChange={(e) => { setVal(e.target.value) }} name="subject" required />
                <textarea placeholder={"Enter Message"} value={val.message} onChange={(e) => { setVal(e.target.value) }} name="message" required />
                <Button variant="outlined" type="submit">Send</Button>
            </form>
        </div>
    )
}

export default ContactForm;


