import Intro from "./intro/Intro";
import TechStackUse from "./techStackUse/TechStackUse";
import Benefits from "./benefits/Benefits";
import Process from "./process/Process";
import IndustriesServed from "./industriesServed/IndustriesServed"
import FAQs from "./FAQs/FAQs";
import Banner from "../../../components/banner/Banner"

function IOSAppDevelopment() {
  return (
    <div>
      <Banner name={"IOS App Development"}/>
      <Intro />
      <TechStackUse />
      <Benefits />
      <Process />
      <IndustriesServed />
      <FAQs />
    </div>
  )
}

export default IOSAppDevelopment;
