import style from "./Benefits.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { data } from "./data";
import {Link} from "react-router-dom"


function Benefits() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Benefits of iOS App <span>Development Services</span></h1>
                </Flip>
                <Fade left>
                    <p>
                    Investing in iOS application development services offers many advantages that can elevate your business and enhance your digital presence.
                    </p>
                </Fade>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <Zoom><img src={ele.img} alt="error" /></Zoom>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><h3>{ele.heading}</h3></Fade>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><p>{ele.text}</p></Fade>
                        </Card>
                    })
                }
                </div>
            </div>
            <div className={style.section3}>
                <h2>Let's Transform Your iOS App <span>Idea into Reality!</span></h2>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Get A Quote</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Benefits;
