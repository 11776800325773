import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom"


function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>iOS Application <span>Development Company</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        At HHP Software, we craft cutting-edge iOS applications that meet your unique business requirements and provide a seamless user experience. Our team of experienced iOS app developers is dedicated to bringing your app ideas to life and taking your business to the next level.
                    </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;
