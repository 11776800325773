import { Card } from '@mui/material';
import style from './DigitalNeeds.module.css'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import { data } from './data';

function DigitalNeeds() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
        <Flip top>
          <h1>Why Choose Us For Your <span>Digital Needs?</span></h1>
        </Flip>
      </div>
      <div className={style.section2}>
        {
          data.map((ele, index) => {
            return <Fade bottom><Card key={index} className={style.box}>
              <Zoom>
                <span>{ele.icon}</span>
              </Zoom>
              <Fade left>
                <h2>{ele.heading}</h2>
              </Fade>
              <Fade right>
                <span>{ele.text}</span>
              </Fade>
            </Card></Fade>
          })
        }
      </div>
    </div>
  )
}

export default DigitalNeeds;
