import MapIcon from '@mui/icons-material/Map';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const data = [
    {
        img: <MapIcon sx={{fontSize:'3rem',color:"#2992e7"}}/>,
        heading: "Our Address",
        details:"J4, Noida, Sector 58, Opposite UFLEX, Uttar Pradesh -201301"
    },
    {
        img: <WatchLaterIcon sx={{fontSize:'3rem',color:"#2992e7"}}/>,
        heading: "Opening Hours",
        details:"Mon - Fri: 09:00am - 09:00pm"
    },
    {
        img: <LocalPhoneIcon sx={{fontSize:'3rem',color:"#2992e7"}}/>,
        heading: "Contact Directly",
        details:["+91 95999-59908","0120-4511388"]
    }
]