import GlobalAccessibility from "../../../digitalMarketing/benefits/images/Pay-Per-ClickAdvertising.webp"
import CrossPlatformCompatibility from "../../../digitalMarketing/benefits/images/SocialMediaMarketing.webp"
import CostEffectiveDevelopment from "../../../digitalMarketing/benefits/images/ContentMarketing.webp"
import EasyUpdatesAndMaintenance from "../../../digitalMarketing/benefits/images/EmailMarketing.webp"
import RealTimeCollaboration from "../../../digitalMarketing/benefits/images/ConversionRateOptimization.webp"
import DataSecurity from "../../../digitalMarketing/benefits/images/AnalyticsAndReporting.webp"


export const data = [
    {
        img: GlobalAccessibility,
        heading: "Global Accessibility",
        text: "Web applications are accessible from anywhere worldwide, provided an internet connection is available. This global accessibility ensures that your business or service can reach a broad audience, expanding your market potential."
    },
    {
        img: CrossPlatformCompatibility,
        heading: "Cross-Platform Compatibility",
        text: "Web apps can be accessed on various devices, including desktop computers, laptops, tablets, and smartphones. They adapt to different screen sizes and operating systems, making them accessible to many users."
    },
    {
        img: CostEffectiveDevelopment,
        heading: "Cost-Effective Development",
        text: "Web apps are generally more cost-effective to develop and maintain than native applications. Instead of building separate apps for different platforms, you can create a single web app that works on multiple devices, reducing development expenses."
    },
    {
        img: EasyUpdatesAndMaintenance,
        heading: "Easy Updates and Maintenance",
        text: "Web applications can be updated and maintained seamlessly. You can make changes, fix bugs, and add new features without requiring users to download and install updates manually, ensuring a smooth user experience."
    },
    {
        img: RealTimeCollaboration,
        heading: "Real-Time Collaboration",
        text: "Web apps enable real-time collaboration and data sharing, making them ideal for businesses that require teamwork and remote collaboration. Multiple users can work on the same documents and projects simultaneously."
    },
    {
        img: DataSecurity,
        heading: "Data Security",
        text: "Web app development services focus on implementing robust security measures. Secure data transmission and storage protect user information from unauthorized access and breaches."
    }
]