import style from "./OurTechStack.module.css"
import { frontendTechStack, backendTechStack, databases } from "./data"
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';


function OurTechStack() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Our Web Application Development <span>Technologies Stack</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        Choosing the right technology stack is crucial for the success of your web application development project. At HHP, we stay at the forefront of technology to ensure that we use the most suitable and cutting-edge tools and frameworks to bring your vision to life. Here is an overview of the key technologies we work with:-
                    </p>
                </Fade>
            </div>
            <div className={style.section2}>
                <Flip top>
                    <h2>Front-end Technologies-</h2>
                </Flip>
                <div className={style.techStack}>
                    {
                        frontendTechStack.map((ele, index) => {
                            return <img src={ele} alt="error" key={index} className={style.logo} />
                        })
                    }
                </div>
                <Flip top>
                <h2>Back-end Technologies-</h2>
                </Flip>
                <div className={style.techStack}>
                    {
                        backendTechStack.map((ele, index) => {
                            return <img src={ele} alt="error" key={index} className={style.logo} />
                        })
                    }
                </div>
                <Flip top>
                <h2>Databases-</h2>
                </Flip>
                <div className={style.techStack}>
                    {
                        databases.map((ele, index) => {
                            return <img src={ele} alt="error" key={index} className={style.logo} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default OurTechStack
