import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import style from "./StrategicVision.module.css"
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { data } from './data';

function StrategicVision() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
      <Flip top>
       <h2>Empowering Your <span>Business Through Our Strategic Vision and Commitment to Excellence.</span></h2>
       </Flip>
      <span className={style.horizontalRuleIcon}>
      <HorizontalRuleIcon sx={{fontSize:"2rem"}}/>
      <HorizontalRuleIcon sx={{fontSize:"2rem"}}/>
      </span> 
      <Fade left>  
      <p>
      We are dedicated to providing innovative solutions and insights to propel your business forward. Our strategic vision, rooted in expertise and forward-thinking, empowers your company with the tools and strategies it needs to thrive in today's dynamic business landscape.  
      </p> 
      </Fade>
      </div>
      <div className={style.section2}>
        {
          data.map((val,index) => {
             return <div key={index} className={style.box}>
              <Fade bottom>
              <img src={val.logo} alt='error'/>
              <h3>{val.heading}</h3>
              <span>{val.text}</span>
              </Fade>
             </div>
          })  
        }
      </div>
    </div>
  )
}

export default StrategicVision;
