import style from './ServicesWeProvide.module.css'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import { data } from './data';
import { Card } from '@mui/material';

function ServicesWeProvide() {
  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
      <Flip top>
      <h1>Our services</h1>
      </Flip>
      </div>
      <div className={style.section2}>
      {
            data.map((ele,index) => {
              return <Fade bottom><Card key={index} className={style.box}>
                <Zoom>
                <img src={ele.img} alt='error'/>
                </Zoom>
                <Fade left>
                <h3>{ele.heading}</h3>
                </Fade>
                <Fade right>
                <span>{ele.text}</span>
                </Fade>
              </Card></Fade>
            })
      }
      </div>
    </div>
  )
}

export default ServicesWeProvide;
