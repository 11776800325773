import DigitalMarketingServices from "./digitalMarketingServices/DigitalMarketingServices";
import Intro from "./intro/Intro"
import Benefits from "./benefits/Benefits"
import FAQs from "./FAQs/FAQs"
import Banner from "../../components/banner/Banner"


function DigitalMarketing() {
  return (
    <div>
      <Banner name={"Digital Marketing"}/>
      <Intro />
      <DigitalMarketingServices />
      <Benefits />
      <FAQs />
    </div>
  )
}

export default DigitalMarketing;
