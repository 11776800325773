import style from "./Benefits.module.css"
import { data } from "./data";
import Button from '@mui/material/Button';
import Card from "@mui/material/Card"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom"


function Benefits() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let buttonName = "Contact Us for a Free Consultation";

    if (windowWidth <= 500) {
        buttonName = "Contact Us";
    }


    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Benefits of Web App <span>Development Services</span></h1>
                    <p>
                        Web application development services offer many advantages for businesses and individuals looking to establish a strong online presence or streamline their operations.
                    </p>
                </Flip>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <Zoom><img src={ele.img} alt="error" /></Zoom>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><h3>{ele.heading}</h3></Fade>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><p>{ele.text}</p></Fade>
                        </Card>
                    })
                }
            </div>
            </div>
            <div className={style.section3}>
                <h2>Ready to Transform Your Ideas into a Powerful Web App? <span>Let's Get Started Today!</span></h2>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">{buttonName}</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Benefits;
