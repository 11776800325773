import IITKanpur_Logo from "../slider/images/IIT_Kanpur_Logo.svg.png"
import anshcreation from "../slider/images/ansh-creation-logo.png"
import babyAmbulance_Logo from "../slider/images/babyAmbulance_logo.png"
import Arthum from "../slider/images/arthum.png"

export const data = [
    {
        logo: IITKanpur_Logo,
        ClientName: "IIT Kanpur",
        text: "HHP Software's web development expertise transformed our online store. Their attention to detail and commitment to enhancing user experience were remarkable. The result? A website that's not only visually stunning but also incredibly functional. We're thrilled with the results but also incredibly functional engagement.",
    },
    {
        logo: anshcreation,
        ClientName: "Ansh Creation",
        text: "HHP Software's web development expertise transformed our online store. Their attention to detail and commitment to enhancing user experience were remarkable. The result? A website that's not only visually stunning but also incredibly functional. We're thrilled with the results but also incredibly functional engagement.",
    },
    {
        logo: babyAmbulance_Logo,
        ClientName: "Baby Ambulance",
        text: "Working with HHP Software on our mobile app development was a game-changer. They understood our vision, engaged in creative collaboration, and delivered a superb educational app. Their team's expertise and professionalism are commendable. We look forward to future projects together engagement.",
    },
    {
        logo: Arthum,
        ClientName: "Arthum",
        text: "HHP Software's mobile app development services have significantly boosted our productivity. Their innovative solutions streamlined our operations and enhanced customer engagement. The return on investment from our collaboration has been outstanding. Thank you for your exceptional work!",
    },
]