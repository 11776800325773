import style from "./BusinessThrive.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Card from '@mui/material/Card';
import Zoom from 'react-reveal/Zoom';
import Button from '@mui/material/Button';
import { data } from "./data";
import { Link } from "react-router-dom"


function BusinessThrive() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h2>Why Do Businesses Thrive with Our <span>Mobile Application Development Expertise?</span></h2>
                </Flip>
                <Fade right>
                    <p>
                        In today's competitive & digital era, having a mobile app can be a game-changer for businesses of all sizes and industries. At HHP Software, we understand that your mobile application is more than just a piece of software; it's a powerful tool for growth and success.
                    </p>
                </Fade>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele, index) => {
                        return <Fade bottom><Card key={index} className={style.box}>
                            <Zoom>
                                <img src={ele.image} alt="error" />
                            </Zoom>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}>
                                <h3>{ele.heading}</h3>
                            </Fade>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}>
                                <p>{ele.explanation}</p>
                            </Fade>
                        </Card></Fade>
                    })
                }
                </div>
            </div>
            <div className={style.section3}>
                <Fade left>
                    <p>READT TO MAKE YOUR MOBILE APP EFFECTIVE ?</p>
                </Fade>
                <Fade right>
                    <Link to="/contactUs"><Button variant="outlined">Get a quote</Button></Link>
                </Fade>
            </div>
        </div>
    )
}

export default BusinessThrive;
