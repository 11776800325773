import img1 from "./images/AndroidAppDevelopment.png"
import img2 from "./images/BlockchainDevelopment.png"
import img3 from "./images/WebApplicationDevelopmentImage.png"
import img4 from "./images/iOSAppDevelopment.png"
import img5 from "./images/DigitalMarketingServices.png"
import img6 from "./images/MobileAppDevelopment.png"


export const data = [
    {   img:img3,
        heading:"Web Application Development",
        text:"Elevate your website with our web developers using the latest technology stack. Define your brand's essence through responsive designs and cutting-edge libraries."
    },
    {   img:img2,
        heading:"Blockchain Development",
        text:"Embrace decentralized and immutable business solutions through our blockchain innovations. Benefit from unbiased blockchain development services with us."
    },
    {   img:img1,
        heading:"Android App Development",
        text:"Unleash the power of Android with our tailored app development services. We specialize in creating user-friendly, scalable, high-performance applications."
    },
    {   img:img4,
        heading:"iOS App Development",
        text:"Expert iOS developers crafting visually stunning, functional, and user-friendly apps in Swift and Objective-C. Tailored solutions aligned with your business objectives."
    },
    {   img:img5,
        heading:"Digital Marketing Services",
        text:"Comprehensive services—SEO, PPC, social media, content marketing—customized to your goals and budget."
    },
    {   img:img6,
        heading:"Mobile App Development",
        text:"Leading app development: We craft robust mobile apps tailored to user needs, budget, and time. Custom Android, iOS, and desktop solutions."
    },
]