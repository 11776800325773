import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import style from "./DrawerNavbar.module.css"
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';



import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        paddingTop: "1rem",
        width: "15rem",
        backgroundImage: 'url("https://t4.ftcdn.net/jpg/05/37/91/97/360_F_537919759_hSQgQ1J9OqynS9h5b1xufu781cUWF6qC.jpg")',
        backgroundSize: 'cover',
    },
    toolbar: theme.mixins.toolbar,
}));

function DrawerNavbar() {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false)

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClick2 = () => {
        setOpen2(!open2)
    }

    const classes = useStyles();

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className='listItems'>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <ListItemButton onClick={toggleDrawer(anchor, false)}
                        sx={{
                            color: "#2992e7",
                        }}>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                    <ListItemButton onClick={toggleDrawer(anchor, false)}
                        sx={{
                            color: "#2992e7",
                        }}
                    >
                        <ListItemText primary="About" />
                    </ListItemButton>
                </Link>
                <ListItemButton onClick={handleClick} id='1'
                    sx={{
                        color: "#2992e7",
                    }}
                >
                    <ListItemText primary="Services" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link to="/services/mobile-application-development" style={{ textDecoration: "none" }}>
                            <ListItemButton sx={{
                                pl: 4,
                                color: "#2992e7",
                            }} onClick={toggleDrawer(anchor, false)}
                            >
                                <ListItemText primary="Mobile Application Development" />
                            </ListItemButton>
                        </Link>
                        <Link to="/services/web-application-development" style={{ textDecoration: "none" }}>
                            <ListItemButton sx={{
                                pl: 4, color: "#2992e7",
                            }} onClick={toggleDrawer(anchor, false)}
                            >
                                <ListItemText primary="Web Application Development" />
                            </ListItemButton>
                        </Link>
                        <Link to="/services/android-app-development" style={{ textDecoration: "none" }}>
                            <ListItemButton sx={{
                                pl: 4, color: "#2992e7",
                            }} onClick={toggleDrawer(anchor, false)}
                            >
                                <ListItemText primary="Android App Development" />
                            </ListItemButton>
                        </Link>
                        <Link to="/services/iOS-app-development" style={{ textDecoration: "none" }}>
                            <ListItemButton sx={{
                                pl: 4, color: "#2992e7",
                            }} onClick={toggleDrawer(anchor, false)}
                            >
                                <ListItemText primary="iOS App Development" />
                            </ListItemButton>
                        </Link>
                        <Link to="/services/blockchain-development" style={{ textDecoration: "none" }}><ListItemButton sx={{
                            pl: 4, color: "#2992e7",
                        }} onClick={toggleDrawer(anchor, false)}>
                            <ListItemText primary="Blockchain Development" />
                        </ListItemButton></Link>
                    </List>
                </Collapse>

                <ListItemButton onClick={handleClick2} id='2'
                    sx={{
                        color: "#2992e7",
                    }}
                >
                    <ListItemText primary="Additional Services" />
                    {open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link to="/additionalServices/IT-Hardware" style={{ textDecoration: "none" }}>
                            <ListItemButton sx={{
                                pl: 4, color: "#2992e7",
                            }} onClick={toggleDrawer(anchor, false)}>
                                <ListItemText primary="IT Hardware" />
                            </ListItemButton>
                        </Link>
                        <Link to="/additionalServices/IT-Manpower" style={{ textDecoration: "none" }}>
                            <ListItemButton sx={{
                                pl: 4, color: "#2992e7",
                            }} onClick={toggleDrawer(anchor, false)}>
                                <ListItemText primary="IT Manpower" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                <Link to="/digitalMarketing" style={{ textDecoration: "none" }} onClick={toggleDrawer(anchor, false)}><ListItem
                    sx={{
                        color: "#2992e7",
                    }}
                >Digital Marketing</ListItem></Link>
                <Link to="/contactUs" style={{ textDecoration: "none" }} onClick={toggleDrawer(anchor, false)}><ListItem
                    sx={{
                        color: "#2992e7",
                    }}
                >Contact Us</ListItem></Link>
            </List>
        </Box>
    )

    return (
        <div className={style.wrapper}>
            <Link to={"/"}><img src='https://hhpsoftware.in/image/HHP%20logo%20(1).webp' alt='error' /></Link>
            {['right'].map((anchor) => (
                <div key={anchor}>
                    <MenuIcon onClick={toggleDrawer(anchor, true)} sx={{ fontSize: "2rem", color: "#2992e7" }} />
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {list(anchor)}
                    </Drawer>
                </div>
            ))}
        </div>
    );
}

export default DrawerNavbar;