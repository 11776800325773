import ReachALucrativeAudience from "../../../digitalMarketing/benefits/images/Pay-Per-ClickAdvertising.webp"
import EnhancedUserExperience from "../../../digitalMarketing/benefits/images/SocialMediaMarketing.webp"
import StrongSecurity from "../../../digitalMarketing/benefits/images/ContentMarketing.webp"
import MonetizationOpportunities from "../../../digitalMarketing/benefits/images/EmailMarketing.webp"
import BrandVisibility from "../../../digitalMarketing/benefits/images/ConversionRateOptimization.webp"
import SupportAndUpdates from "../../../digitalMarketing/benefits/images/AnalyticsAndReporting.webp"


export const data = [
    {
        img: ReachALucrativeAudience,
        heading: "Reach a Lucrative Audience",
        text: "iOS users represent a desirable and often more affluent demographic. Developing an iOS app allows you to tap into this market segment, potentially increasing your revenue and customer base."
    },
    {
        img: EnhancedUserExperience,
        heading: "Enhanced User Experience",
        text: "Apple's stringent guidelines ensure that iOS apps provide a seamless and consistent user experience. It means your app will be user-friendly, visually appealing, and intuitive, which can lead to higher user satisfaction and retention."
    },
    {
        img: StrongSecurity,
        heading: "Strong Security",
        text: "iOS is renowned for its robust security measures. By choosing iOS app development, you enhance the safety of your app and protect sensitive user data, fostering trust and credibility."
    },
    {
        img: MonetizationOpportunities,
        heading: "Monetization Opportunities",
        text: "iOS apps offer monetization options, including in-app purchases, subscriptions, and advertisements. This flexibility allows you to generate revenue and adapt your strategy as your business evolves."
    },
    {
        img: BrandVisibility,
        heading: "Brand Visibility",
        text: "A presence on the Apple App Store can enhance your brand's visibility and credibility. A well-designed iOS app can create a lasting impression on users, reinforcing your brand identity."
    },
    {
        img: SupportAndUpdates,
        heading: "Support and Updates",
        text: "Apple continuously supports and updates its devices and operating systems. With iOS app development, you can expect ongoing compatibility and timely updates for your app."
    }
]