import ExperiencedTeam from "../../../digitalMarketing/benefits/images/Pay-Per-ClickAdvertising.webp"
import CuttingEdgeTechnology from "../../../digitalMarketing/benefits/images/SocialMediaMarketing.webp"
import UserCentricApproach from "../../../digitalMarketing/benefits/images/ContentMarketing.webp"
import AgileDevelopment from "../../../digitalMarketing/benefits/images/EmailMarketing.webp"
import RobustTesting from "../../../digitalMarketing/benefits/images/ConversionRateOptimization.webp"
import PostLaunchSupport from "../../../digitalMarketing/benefits/images/AnalyticsAndReporting.webp"


export const data = [
    {
        img: ExperiencedTeam,
        heading: "Experienced Team",
        text: "Our dedicated Android developers boast extensive experience in crafting high-quality apps. With their expertise, they can tailor solutions to meet your specific business requirements."
    },
    {
        img: CuttingEdgeTechnology,
        heading: "Cutting-Edge Technology",
        text: "As an Android application development company, we stay at the forefront of technology advancements. By leveraging cutting-edge tools, frameworks, and libraries, we ensure your app is built using the most efficient and innovative solutions."
    },
    {
        img: UserCentricApproach,
        heading: "User-Centric Approach",
        text: "At HHP Software, an Android app development company, we prioritize the end-user experience. Our team conducts comprehensive user research and applies intuitive design principles to create Android apps that resonate with users."
    },
    {
        img: AgileDevelopment,
        heading: "Agile Development",
        text: "We adhere to an agile development methodology, enabling us to deliver your Android app in iterative stages. This approach ensures flexibility, transparency, and efficient collaboration, allowing us to incorporate feedback readily."
    },
    {
        img: RobustTesting,
        heading: "Robust Testing",
        text: "Quality assurance is at the core of our practices to ensure your Android app functions seamlessly across all platforms. Our rigorous testing procedures guarantee a smooth, bug-free user experience."
    },
    {
        img: PostLaunchSupport,
        heading: "Post-Launch Support",
        text: "Our commitment to your success extends well beyond the app's launch. We provide ongoing post-launch support, encompassing monitoring, maintenance, and updates to keep your app up to date and performing optimally."
    }
]