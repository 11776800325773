import ExpertiseInDiverseIndustries_Logo from "./images/ExpertiseInDiverseIndustries.webp"
import HolisticApproachInBusiness_Logo from "./images/HolisticApproachInBusiness.jpg"
import CustomSolutionsForUniqueChallenges from "./images/CustomSolutionsForUniqueChallenges.jpg"
import CuttingEdgeTechnology from "./images/Cutting-EdgeTechnology.jpeg"
import ProvenResults from "./images/ProvenResults.webp"
import ClientCentricApproach from "./images/Client-CentricApproach.png"

export const data = [
    {
        logo: ExpertiseInDiverseIndustries_Logo,
        heading: "Expertise in Diverse Industries",
        text: "We have a proven track record of successfully implementing digital solutions across various industries. Our expertise spans healthcare, finance, e-commerce, education, and more, allowing us to tailor strategies that align with your industry-specific challenges and goals."
    },
    {
        logo: HolisticApproachInBusiness_Logo,
        heading: "Holistic Approach in Business",
        text: "We take a holistic view of digital transformation, combining technology, strategy, Digital marketing, and user-centric design. Our approach ensures that your digital initiatives are technically sound and resonate with your audience."
    },
    {
        logo: CustomSolutionsForUniqueChallenges,
        heading: "Custom Solutions for Unique Challenges",
        text: "No two businesses are alike, and neither are their digital transformation needs. We collaborate closely with you to understand your unique challenges and craft customized solutions that address them effectively."
    },
    {
        logo: CuttingEdgeTechnology,
        heading: "Cutting-Edge Technology",
        text: "Staying ahead in the digital race requires leveraging the latest technology trends. We are committed to incorporating cutting-edge technologies and more to future-proof your digital transformation."
    },
    {
        logo: ProvenResults,
        heading: "Proven Results",
        text: "Our portfolio is a testament to our ability to drive results. We've helped businesses enhance efficiency, reduce costs, improve customer experiences, and increase revenue through digital transformation."
    },
    {
        logo: ClientCentricApproach,
        heading: "Client-Centric Approach",
        text: "Your goals are at the heart of our strategy. We collaborate closely with you, keeping your objectives at the forefront of our digital transformation initiatives. Choose HHP Software to lead your digital transformation journey."
    }
]