import Intro from "./intro/Intro"
import ContactInfo from "./contactInfo/ContactInfo"
import NewsLetter from "../about/newsletter/NewsLetter"
import ContactForm from "./contactForm/ContactForm"
import GoogleMap from "./googleMap/GoogleMap"
import Banner from "../../components/banner/Banner"

function ContactUs() {
  return (
    <div>
      <Banner name={"Contact Us"} />
      <Intro />
      <ContactInfo />
      <ContactForm />
      <GoogleMap />
      <NewsLetter />
    </div>
  )
}

export default ContactUs;
