import EnhancedSecurity from "../../../digitalMarketing/benefits/images/Pay-Per-ClickAdvertising.webp"
import Transparency from "../../../digitalMarketing/benefits/images/SocialMediaMarketing.webp"
import CostSavings from "../../../digitalMarketing/benefits/images/ContentMarketing.webp"
import Efficiency from "../../../digitalMarketing/benefits/images/EmailMarketing.webp"
import GlobalReach from "../../../digitalMarketing/benefits/images/ConversionRateOptimization.webp"
import Innovation from "../../../digitalMarketing/benefits/images/AnalyticsAndReporting.webp"


export const data = [
    {
        img: EnhancedSecurity,
        heading: "Enhanced Security",
        text: "Blockchain technology employs advanced cryptography and decentralized consensus mechanisms, making it highly resistant to data breaches and fraud. It offers a secure platform for transactions and data sharing."
    },
    {
        img: Transparency,
        heading: "Transparency",
        text: "Blockchain provides an immutable and transparent ledger of all transactions. This transparency fosters trust among users, as they can independently verify and track the history of transactions and data."
    },
    {
        img: CostSavings,
        heading: "Cost Savings",
        text: "Blockchain reduces operational costs by automating processes through smart contracts and eliminating intermediaries. It streamlines various business functions, leading to significant cost savings over time."
    },
    {
        img: Efficiency,
        heading: "Efficiency",
        text: "Blockchain simplifies and accelerates processes, reducing the need for manual verification and reconciliation. This improved efficiency can result in faster transactions, reduced errors, and increased productivity."
    },
    {
        img: GlobalReach,
        heading: "Global Reach",
        text: "Blockchain operates on a decentralized network, making it accessible to anyone with an internet connection. This global reach facilitates borderless transactions and collaborations, expanding your market reach."
    },
    {
        img: Innovation,
        heading: "Innovation",
        text: "Blockchain technology opens up new possibilities for businesses, enabling them to create innovative products and services. Smart contracts and decentralized applications (DApps) are examples of blockchain's innovative solutions."
    }
]