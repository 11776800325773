import style from "./IndustriesServed.module.css"
import iOSAppDevelopment from "./iosAppDevelopment.png"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { data } from "./data";

function IndustriesServed() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <div className={style.heading}>
                    <Fade left>
                        <h3>Industries We Have Served As An IOS Application Development Company</h3>
                        <p>
                            HHP Software excels in iPhone/iOS application development across various industries. This versatility enables us to create innovative digital solutions tailored to address unique challenges and leverage emerging possibilities. Our deep industry expertise and experience equip us to design iOS apps that drive expansion, optimize processes, and elevate user interactions. Our iOS application development services have significantly impacted various sectors, making valuable contributions to each.
                        </p>
                    </Fade>
                </div>
                <div className={style.imageContainer}>
                    <Fade right>
                        <img src={iOSAppDevelopment} alt="error" />
                    </Fade>
                </div>
            </div>
            <div className={style.section2}>
                {
                    data.map((ele, index) => {
                        return <div key={index} className={style.box}>
                            <Flip top>
                                <span>{ele.logo}</span>
                                <p>{ele.name}</p>
                            </Flip>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default IndustriesServed;
