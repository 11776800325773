import style from "./TechStackUse.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

function TechStackUse() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>iOS Application Development <span>Technologies Stack</span></h1>
                </Flip>
                <Fade left>
                    <p>
                    A deep understanding of the iOS Application Development Technologies Stack is crucial for creating apps that excel in performance, security, and user experience. By harnessing the power of these technologies, we ensure your iOS app aligns seamlessly with Apple's guidelines, functions flawlessly, and delivers the features your users demand.
                    </p>
                    <p>
                    Our team of skilled iOS developers is well-versed in leveraging this technology stack to craft iOS applications that stand out in the competitive app market. Whether you have a groundbreaking app idea or want to enhance your existing iOS app, we have the expertise to make it happen.
                    </p>
                </Fade>
            </div>
        </div>
    )
}

export default TechStackUse;
