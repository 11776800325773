import style from "./TechStackUse.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

function TechStackUse() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Android Application Development <span>Technologies Stack</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        At HHP Software, we strive to surpass our clients' expectations through our outstanding Android app development services. Our Android app developers harness the capabilities of various programming languages, such as Java, C++, and Kotlin, while keeping abreast of the latest Android application development trends and OS updates. With us, you can anticipate digital products that seamlessly align with your unique business needs.
                    </p>
                </Fade>
            </div>
        </div>
    )
}

export default TechStackUse;
