export const data = {
    "title": "FAQs Based on Android App Development Services",
    "rows":
        [
            {
                title: "What Types of Android Apps Can You Develop?",
                content: "Our team of seasoned developers possesses the expertise to craft various Android applications. These include, but are not limited to, e-commerce apps, social networking platforms, educational apps, healthcare solutions, travel booking applications, and more.",
            },
            {
                title: "Do You Offer Cross-Platform App Development for Android and iOS?",
                content: "Absolutely. We provide cross-platform app development using frameworks such as React Native and Flutter. This approach allows us to create apps seamlessly operating on Android and iOS devices, significantly saving development time and costs.",
            },
            {
                title: "How Long Does it Take to Develop a Custom Android App?",
                content: "The timeline for developing a custom Android app hinges on the project's complexity and scope. We thoroughly analyze your requirements and provide an estimated timeline during our initial consultation.",
            },
            {
                title: "What Is the Cost of Developing an Android Application?",
                content: "The cost of developing a bespoke app can vary considerably, contingent on several factors. These factors include the app's complexity, required features and functionalities, chosen platform (Android, iOS, or cross-platform), the location and expertise of the development team, and the project timeline.",
            },
            {
                title: "Why Choose HHP Software as Your Android Application Development Company?",
                content: "With over 5+ years of experience in Android app development, we boast a team of skilled engineers capable of delivering impactful solutions. Our diverse portfolio includes IoT, blockchain, beacons, and other cutting-edge technologies. We take pride in having served top brands and assisting numerous SMBs and startups in bringing their bold ideas to life. Trust us to craft innovative Android app solutions tailored to your unique needs.",
            },
            {
                title: "Do You Provide Post-Launch Support and Maintenance for Android Apps?",
                content: "Indeed, we offer post-launch support and maintenance services to ensure that your Android app continues to perform optimally. Our team can readily address issues, implement updates, and provide ongoing assistance."
            },
            {
                title: "What Is Our Approach to UI/UX Design for Android Apps?",
                content: "We adhere to a user-centric design approach, meticulously crafting visually appealing and intuitive interfaces for Android apps. Our designers undertake extensive research to understand your target audience, resulting in a seamless and engaging user experience."
            },
            {
                title: "How Do You Ensure the Security of Android Apps During Development?",
                content: "Security is paramount to us. We follow best practices in secure coding, conduct exhaustive testing, and implement encryption and other security measures to safeguard your Android app and its data from potential vulnerabilities."
            }
        ]
}