import Services from "../services/Services.jsx";
import AdditionalServices from "../additionalServices/AdditionalServices";

export const pages = [
    {
        link:"/",
        name:"Home",
    },
    {
        link:"/about",
        name:"About",
    },
    {
        name:<Services/>,
    },
    {
        link:"/digitalMarketing",
        name:"Digital Marketing",
    },
    {
        name:<AdditionalServices/>,
    },
    {
        link:"/contactUs",
        name:"Contact Us",
    }
]