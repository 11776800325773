import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom"

function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Android Application <span>Development Company</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        HHP Software is a prominent Android app development firm celebrated for its trustworthiness and proficiency. Through our extensive Android app development offerings, we commit ourselves to providing high-quality solutions designed to suit your precise demands.
                    </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;
