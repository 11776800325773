import style from "./Footer.module.css"
import { ourServices } from "./data";
import { Link } from "react-router-dom";
import { socialMediaIcons } from "../about/teamMember/data";

function Footer() {

    return (
        <div className={style.mainContainer}>
            <div className={style.wrapper}>
                <div className={style.section1}>
                    <h2>HHP SOFTWARE</h2>
                    <p>
                        HHP Software is a leading mobile app development company in the US, UK
                        s & India. We offer innovative digital solutions to our clients across industries.
                    </p>
                    <div className={style.socialMediaLinks}>
                        {
                            socialMediaIcons.map((ele, index) => {
                                return <Link to={ele.link} className={style.socialMediaIcons} target="_blank" rel="noopener noreferrer" ><span key={index}>{ele.icon}</span></Link>
                            })
                        }
                    </div>
                </div>
                <ul className={style.ul}>
                    <h2>Our Services</h2>
                    {
                        ourServices.map((val, index) => {
                            return <Link to={val.link} key={index} className={style.link}><li>{val.name}</li></Link>
                        })
                    }
                </ul>
                <div className={style.address}>
                    <h2>Company Address</h2>
                    <p><span>Address:</span> J4, Noida Sec-58, OPPOSITE UFLEX, UP (201301)</p>
                    <p><span>Phone:</span> 0120-4511388</p>
                    <p><span>Whatsapp:</span> +91 9599 9599 08</p>
                    <p><span>Email:</span> support@hhpsoftware.in</p>
                </div>
            </div>
            <div className={style.copyRight}>
                <p>HHP Software © All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer;
