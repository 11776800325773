import style from "./GoogleMap.module.css"

function GoogleMap() {
    return (
        <div className={style.mainContainer}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.75772012131!2d77.35700007450896!3d28.607044285239414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce53cfc73ea37%3A0xac3d58ac39c7b49a!2sHHP%20Software%20Company%20in%20Delhi%20India!5e0!3m2!1sen!2sin!4v1704517531259!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className={style.map} title="map"></iframe>
        </div>
    )
}

export default GoogleMap;
