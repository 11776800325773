import WhatWeDo from "./whatWeDo/WhatWeDo.jsx"
import StrategicVision from './strategicVision/StrategicVision.jsx';
import Innovation from './innovation/Innovation.jsx';
import LogoSlider from './slider/LogoSlider.jsx';
import ChooseUs from './chooseUs/ChooseUs.jsx';
import ClientSay from './clientSay/ClientSay.jsx';

function Home() {
  return (
    <div>
      <WhatWeDo/>
      <StrategicVision/>
      <Innovation/>
      <LogoSlider/>
      <ChooseUs/>
      <ClientSay/>
    </div>
  )
}

export default Home;
