import "./App.css"
import { useEffect } from "react";
import Header from "./pages/header/Header";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import ContactUs from "./pages/contactUs/ContactUs";
import Footer from "./pages/footer/Footer";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import DigitalMarketing from "./pages/digitalMarketing/DigitalMarketing";
import DrawerNavbar from "./pages/drawerNavbar/DrawerNavbar";
import MobileApplicationDevelopment from "./pages/services/mobileApplicationDevelopment/MobileApplicationDevelopment";
import WebApplicationDevelopment from "./pages/services/webApplicationDevelopment/WebApplicationDevelopment";
import AndroidAppDevelopment from "./pages/services/androidAppDevelopment/AndroidAppDevelopment";
import IOSAppDevelopment from "./pages/services/iOSAppDevelopment/IOSAppDevelopment";
import BlockchainDevelopment from "./pages/services/blockchainDevelopment/BlockchainDevelopment";
import ITHardware from "./pages/additionalServices/IT-Hardware/IT-Hardware";
import ITManpower from "./pages/additionalServices/IT-Manpower/IT-Manpower";

function App() {

  function GoToTop() {
    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
      onTop()
    }, [routePath]);

    return null;
  }


  return (
    <div className="App">
      <BrowserRouter>
        <GoToTop />
        <DrawerNavbar />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/digitalMarketing" element={<DigitalMarketing />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/services/mobile-application-development" element={<MobileApplicationDevelopment />} />
          <Route path="/services/web-application-development" element={<WebApplicationDevelopment />} />
          <Route path="/services/android-app-development" element={<AndroidAppDevelopment />} />
          <Route path="/services/iOS-app-development" element={<IOSAppDevelopment />} />
          <Route path="/services/blockchain-development" element={<BlockchainDevelopment />} />
          <Route path="/additionalServices/IT-Hardware" element={<ITHardware />} />
          <Route path="/additionalServices/IT-Manpower" element={<ITManpower />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;





