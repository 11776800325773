import { data } from "./data";
import DropDown from "../../components/dropDown/DropDown";

function AdditionalServices() {
    return (
        <div>
            <DropDown name={"Additional Services"} data={data} />
        </div>
    );
}

export default AdditionalServices;