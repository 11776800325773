import style from "./IndustriesServed.module.css"
import industriesImage from "./industriesImage.png"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { data } from "./data";

function IndustriesServed() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <div className={style.heading}>
                    <Fade left>
                        <h3>Industries We Have Served As A Web Application Development Company</h3>
                        <p>
                            HHP Software specializes in crafting custom web applications for diverse industries. Our expertise spans various sectors, allowing us to design tailored solutions for your unique business needs. Contact us to explore how we can contribute to your success. Your mission is our priority!
                        </p>
                    </Fade>
                </div>
                <div className={style.imageContainer}>
                    <Fade right>
                        <img src={industriesImage} alt="error" />
                    </Fade>
                </div>
            </div>
            <div className={style.section2}>
                {
                    data.map((ele, index) => {
                        return <div key={index} className={style.box}>
                            <Flip top>
                            <span>{ele.logo}</span>
                            <p>{ele.name}</p>
                            </Flip>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default IndustriesServed;
