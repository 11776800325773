import Intro from "./intro/Intro";
import TechStackUse from "./techStackUse/TechStackUse";
import Benefits from "./benefits/Benefits";
import Process from "./process/Process";
import FAQs from "./FAQs/FAQs";
import Banner from "../../../components/banner/Banner"

function AndroidAppDevelopment() {
  return (
    <div>
      <Banner name={"Android App Development"}/>
      <Intro />
      <TechStackUse />
      <Benefits />
      <Process />
      <FAQs />
    </div>
  )
}

export default AndroidAppDevelopment;
