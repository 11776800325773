import style from "./Intro.module.css"
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import { Link } from "react-router-dom"


function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Digital Marketing <span>Services</span></h1>
                </Flip>
                <Fade left>
                    <p>
                        AEffective online marketing is essential for businesses of all sizes in today's fast-paced digital landscape. Whether you're looking to boost your online presence, drive more traffic to your website, or increase your sales and conversions, our expert digital marketing team is here to help.
                    </p>
                </Fade>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Talk to us</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Intro;
