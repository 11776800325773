export const data = [
    {
        heading:"Strategy",
        text:"We start by getting to know your business, goals, and target audience. Our team collaborates with you to define the project scope, set objectives, and create a detailed roadmap for development."
    },
    {
        heading:"Design",
        text:"Our talented designers create intuitive, visually appealing user interfaces that ensure an exceptional user experience. We pay attention to every detail, from layout and navigation to color schemes and branding."
    },
    {
        heading:"Development",
        text:"Before your web application goes live, it undergoes rigorous testing to ensure functionality, security, and performance. We fix any issues and ensure your app meets the highest quality standards."
    },
    {
        heading:"Testing",
        text:"Our experienced developers use the latest technologies and best practices to build your web application. We follow a transparent and agile development process, keeping you in the loop at every step."
    },
    {
        heading:"Launch & Maintenance",
        text:"Once everything is thoroughly tested and refined, we deploy your web application to a production environment. We handle the technical aspects so you can focus on what matters most to your business."
    },
]