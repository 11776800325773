import style from "./OurApproach.module.css";
import Card from '@mui/material/Card';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { data } from "./data";

function OurApproach() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                <h2>Our Approach - Mobile Application Development Services</h2>
                <p>At HHP Software- A top mobile application development company, our approach is rooted in innovation, collaboration, and a commitment to delivering exceptional results. We understand that every project is unique and tailor our approach to meet our client's needs and goals.</p>
                </Flip>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele,index) => {
                        return <Card key={index} className={style.box}>
                              <Fade bottom>
                              <h3>{ele.heading}</h3>   
                              <p>{ele.text}</p>
                              </Fade>
                         </Card>
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default OurApproach;
