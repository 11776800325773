import style from "./ClientSay.module.css";
import Fade from 'react-reveal/Fade';
import { data } from "./data";
import Card from '@mui/material/Card';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";

function ClientSay() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let slidesToShow = 3;

  if (windowWidth <= 1140) {
    slidesToShow = 2;
  }
  if (windowWidth <= 750) {
    slidesToShow = 1;
  }


  let settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: false,
    pauseOnHover: true
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.section1}>
        <Fade bottom>
          <h2>What Our Client Says</h2>
        </Fade>
      </div>
      <span className={style.horizontalRuleIcon}>
        <HorizontalRuleIcon sx={{ fontSize: "2rem" }} />
        <HorizontalRuleIcon sx={{ fontSize: "2rem" }} />
      </span>
      <Slider {...settings} className={style.section2}>
        {
          data.map((val, index) => {
            return <Card key={index} className={style.box} >
              <div className={style.logoAndName}>
                <img src={val.logo} alt="error" />
                <h3>{val.ClientName}</h3>
              </div>
              <span>{val.text}</span>
            </Card>
          })
        }
      </Slider>
    </div>
  )
}

export default ClientSay;