import style from "./Benefits.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { data } from "./data";
import { Link } from "react-router-dom";


function Benefits() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Benefits of <span>Digital Marketing Services</span></h1>
                </Flip>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <Zoom><img src={ele.img} alt="error" /></Zoom>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><h3>{ele.heading}</h3></Fade>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><p>{ele.text}</p></Fade>
                        </Card>
                    })
                }
                </div>
            </div>
            <div className={style.section3}>
                <h2>Get Started <span>Today</span></h2>
                <p>Ready to take your digital marketing to the next level? Contact us today for a free consultation. Our team will assess your needs and work with you to develop a personalized digital marketing strategy that drives growth and success for your business. Don't miss out on the countless opportunities digital marketing can provide – let us help you reach your online goals.</p>
                <Bounce>
                    <Link to="/contactUs"><Button variant="outlined">Get A Quote</Button></Link>
                </Bounce>
            </div>
        </div>
    )
}

export default Benefits;
