import style from "./Process.module.css"
import Card from "@mui/material/Card"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { data } from "./data";

function Process() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>Our iOS App <span>Development Process</span></h1>
                </Flip>
            </div>
            <div className={style.section2}>
                <div className={style.cardWrapper}>
                {
                    data.map((ele, index) => {
                        return <Card key={index} className={style.box}>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><h3>{ele.heading}</h3></Fade>
                            <Fade left={index % 2 === 0} right={!index % 2 === 0}><p>{ele.text}</p></Fade>
                        </Card>
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default Process;
