import style from "./Intro.module.css"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

function Intro() {
    return (
        <div className={style.mainContainer}>
            <div className={style.section1}>
                <Flip top>
                    <h1>CONTACT US</h1>
                </Flip>
                <Fade left>
                    <p>
                        Thank you for considering HHP Software for your web and mobile app development needs. We value your interest and look forward to connecting with you. Whether you have questions, want to discuss a project, or just want to say hello, we're here to assist you.
                    </p>
                </Fade>
            </div>
        </div>
    )
}

export default Intro;
