export const data = [
    {
        heading: "Consultation",
        text: "We begin the process with a comprehensive consultation. We work closely with you during this phase to understand your business goals, objectives, and challenges. This step is crucial in defining the project's scope and identifying the specific requirements."
    },
    {
        heading: "Design & User Experience",
        text: "After gathering all the necessary information, our expert team of blockchain developers designs a customized solution that aligns with your objectives. We create a detailed project plan, which includes timelines, milestones, and budget estimates. This plan serves as the roadmap for the entire development process."
    },
    {
        heading: "Development",
        text: "Our skilled developers then take over, using their expertise to code and program the blockchain solution. Whether you need a private, public, or consortium blockchain, we leverage the latest technologies and best practices to bring your project to life."
    },
    {
        heading: "Testing & Quality Assurance",
        text: "We understand the critical importance of security and reliability in blockchain applications. Before deploying the solution, we conduct rigorous testing and quality assurance procedures. This ensures your blockchain project is secure, free from bugs, and capable of delivering the expected performance."
    },
    {
        heading: "Deployment",
        text: "Once the solution passes the testing phase, we proceed with its deployment. Our team ensures a seamless integration of the blockchain into your existing systems. We handle all the technical aspects to minimize disruptions and ensure a smooth transition."
    },
    {
        heading: "Maintenance & Support",
        text: "Our commitment to your project continues after deployment. We offer ongoing maintenance and support to keep your blockchain solution up-to-date and running smoothly. This includes software updates, bug fixes, and continuous monitoring to ensure the security and efficiency of your blockchain application."
    }
]